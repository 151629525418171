<template>
	<div class="content">
		<section class="head-section">
			<div class="container cols-2">
				<div class="content">
					<h1 class="title">
						<span
						data-aos="fade-right"
					data-aos-duration="1500">Ландшафтний</span> 
					<span
					data-aos="fade-left"
					data-aos-duration="1000" class="left-move">дизайн</span></h1>
					<div class="inner-container content cols-2">
						<div class="images-col"
						data-aos="opacity-animation"
						data-aos-delay="300" 
						data-aos-duration="2000">
							<picture class="full-image" >
								<source srcset="../assets/images/lanscape-2.webp" type="image/webp">
								<img src="../assets/images/lanscape-2.jpg" alt="Lember Flowers" :ref="setImagemRef">
							</picture>
						</div>
						<div class="content"
						data-aos="opacity-animation"
						data-aos-delay="300" 
						data-aos-duration="2000">
							<div>
								<p>Озеленення прибудинкової чи комерційної території, висадка дерев у саду: проєктування та реалізація.</p>
								<router-link to="#consultation" class="btn">ХОЧУ КОНСУЛЬТАЦІЮ</router-link>
							</div>
						</div>
					</div>
				</div>
				<div class="images-col"
					data-aos="opacity-animation"
					data-aos-delay="300" 
					data-aos-duration="2000">
					
					<picture class="full-image" >
						<source srcset="../assets/images/lanscape-1.webp" type="image/webp">
						<img src="../assets/images/lanscape-1.jpg" alt="Lember Flowers" :ref="setImagemRef">
					</picture>
				</div>
			</div>
		</section>
		<section class="count-item-section">
			<div class="container">
				<h2 class="title">Купуючи рослини самостійно, люди часто зіштовхуються з проблемами:</h2>
				<div class="flower-bg"></div>
				<div class="conunts">
					<div class="count-item">
						<p>як правильно створити умови для висадки?</p>
					</div>
					<div class="count-item">
						<p>чи приживеться дерево/кущ/квітка?</p>
					</div>
					<div class="count-item">
						<p>яким має бути ґрунт?</p>
					</div>
					<div class="count-item">
						<p>як правильно доглядати за рослинами?</p>
					</div>
				</div>
			</div>
		</section>
		<section class="bg-section">
			
			<div class="container">
				<h2 class="title">З Lemberg Flowers вам не доведеться перейматися цими проблемами!</h2>
			</div>
				<div class="images-col">
					
					<picture class="full-image" >
						<source srcset="../assets/images/lanscape-3.webp" type="image/webp">
						<img src="../assets/images/lanscape-3.jpg" alt="Lember Flowers" :ref="setImagemRef">
					</picture>
				</div>
			<div class="container">
				<div class="items-wrap">
					<div class="cols-3 conunts">
						<div class="count-item">
							<p>У нас є команда, кожен учасник якої має конкретне завдання</p>
						</div>
						<div class="count-item">
							<p>Ми проєктуємо дизайн, робимо візуалізацію, щоб ви зрозуміли, як виглядатиме готовий проєкт</p>
						</div>
						<div class="count-item">
							<p>Виконуємо роботи комплексно, від проєктування та підготовки території – до висадки рослин</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="image-cols-section">
			<div class="container">
				<div class="image-cols-wrap">
					<div class="cols-2">
						<div>
							<p>Ви можете спробувати зробити ландшафтний дизайн своїми руками, витратити на це багато коштів і переживати за результат.</p>
						</div>
						<div class="bg-col">
							<p>або ж замовити наші послуги і насолоджуватися красою озелененої території та функціонально її використовувати.</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="video-section">
			<div class="bg-video">
				<video loop="loop" autoplay="autoplay" preload="auto" muted="muted" playsinline="" class="screen-video" :data-src="video" :src="video"></video>
			</div>
			<div class="container cols-2 conunts">
				<div><h2 class="title">Як це працює?</h2></div>
				<div class="cols-2">
					<div class="count-item">
						<p class="title">Консультація по телефону</p>
						<span>Фіксуємо потреби клієнта, обговорюємо варіанти реалізації, узгоджуємо орієнтовні об'єми роботи і кошторис.</span>
					</div>
					<div class="count-item">
						<p class="title">Виїзд фотографа і дизайнера</p>
						<span>Зустріч з клієнтом на об'єкті. Фотофіксація та детальне обговорення побажань клієнта.</span>
					</div>
					<div class="count-item">
						<p class="title">Пропозиція</p>
						<span>Підготовка рендерів об'єкта з варіантами реалізації.</span>
					</div>
					<div class="count-item">
						<p class="title">Доставка і монтаж</p>
						<span>Згідно проєкту все доставляємо і монтуємо на місці.</span>
					</div>
				</div>
			</div>
		</section>
		<section class="section-gallery">
			<div class="container">
				<div class="gallery">
					<div v-for="(img, index) in gallery"
					:key="index" :class="'pic'"
					@click="() => showGalleryImg(index)">
						<picture >
							<source :srcset="typeof img === 'string' ? img : img.webp" type="image/webp">
							<img :src="typeof img === 'string' ? img : img.thumbnail" alt="Lember Flowers" :ref="setImagemRef">
						</picture>
					</div>
					<vue-easy-lightbox
					:visible="visibleImg"
					:imgs="gallery"
					:index="indexGallery"
					:moveDisabled = "moveDisabled"
					:scrollDisabled = "scrollDisabled"
					:swipeTolerance = "swipeTolerance"
					:escDisabled="escDisabled"
					:loop="loop"
					@hide="handleGalleryHide">
						
					</vue-easy-lightbox>
				</div>
			</div>
		</section>
		<section>
			<div class="container">
				<v-form/>
			</div>
		</section>
		<section>
			<v-contacts/>
		</section>
	</div>
</template>

<style scoped lang="scss">
	@import "../assets/scss/components/landscape-page.scss";
</style>

<script>
	import VueEasyLightbox from 'vue-easy-lightbox' 
	import ContactForm from '@/components/ContactForm.vue'
	import Contacts from '@/components/Contacts.vue'

	import { ref, onMounted, onBeforeUpdate } from 'vue'


	export default {
		setup() {
			let imageRefs = ref([])
			const setImagemRef = el => {
				if (el) {
					imageRefs.value.push(el)
				}
			}
		onMounted(() => {
			imageRefs.value.forEach(function(entry) {
				entry.width = entry.clientWidth
				entry.height = entry.clientHeight
			});
		})
		onBeforeUpdate(() => {
			imageRefs.value = []

		})
			return {
				setImagemRef
			}
		},
		components: {
			VueEasyLightbox,
			'v-contacts': Contacts,
			'v-form': ContactForm
		},
		data() {
			return {
				visibleImg: false,
				indexGallery: 0,
				moveDisabled:true,
				scrollDisabled : true,
				swipeTolerance : 50,
				escDisabled : true,
				loop: true,
				gallery: [
					{
						src: require('../assets/images/landscape-gallery/image-1.jpg'),
						webp: require('../assets/images/landscape-gallery/small-1.webp'),
						thumbnail: require('../assets/images/landscape-gallery/small-1.jpg') 
					},
					{
						src: require('../assets/images/landscape-gallery/image-2.jpg'),
						webp: require('../assets/images/landscape-gallery/small-2.webp'),
						thumbnail: require('../assets/images/landscape-gallery/small-2.jpg') 
					},
					{
						src: require('../assets/images/landscape-gallery/image-3.jpg'),
						webp: require('../assets/images/landscape-gallery/small-3.webp'),
						thumbnail: require('../assets/images/landscape-gallery/small-3.jpg') 
					},
					{
						src: require('../assets/images/landscape-gallery/image-4.jpg'),
						webp: require('../assets/images/landscape-gallery/small-4.webp'),
						thumbnail: require('../assets/images/landscape-gallery/small-4.jpg') 
					}
				],
				video: require('../assets/images/AdobeStock_1.mp4')
			}
		},
		methods: {
			showGalleryImg(index) {
				this.indexGallery = index
				this.visibleImg = true
			},
			handleGalleryHide() {
				this.visibleImg = false
			}
		}
	}
</script>